<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('purchaseOrders') }}
        </h1>
      </b-col>
    </b-row>
    <div class="d-flex flex-wrap">
      <b-form-group
        :label="$t('paymentStatus')"
        class="mr-1"
      >
        <b-form-select
          v-model="paymentStatus"
          :options="statusOptions"
          @change="handleFetchQuotes($event)"
        />
      </b-form-group>
      <b-form-group
        :label="$t('customer')"
        class="mr-1"
      >
        <b-form-radio-group
          v-model="customerInvoiced"
          buttons
          button-variant="outline-primary"
          @change="handleFetchQuotes"
        >
          <b-form-radio :value="null">
            {{ $t('all') }}
          </b-form-radio>
          <b-form-radio :value="true">
            {{ $t('invoiced') }}
          </b-form-radio>
          <b-form-radio :value="false">
            {{ $t('pending') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        :label="$t('supplier')"
        class="mr-1"
      >
        <b-form-radio-group
          v-model="supplierInvoiced"
          buttons
          button-variant="outline-primary"
          @change="handleFetchQuotes"
        >
          <b-form-radio :value="null">
            {{ $t('all') }}
          </b-form-radio>
          <b-form-radio :value="true">
            {{ $t('invoiced') }}
          </b-form-radio>
          <b-form-radio :value="false">
            {{ $t('pending') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <b-row v-if="quotes.length > 0">
      <b-col>
        <b-overlay :show="loading">
          <quotes-table
            :quotes="quotes"
            no-tools
            @refetch="handleFetchQuotes"
          />
        </b-overlay>
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-pagination
              :value="quotesPagination.page"
              :total-rows="quotesPagination.total_objects"
              :per-page="quotesPagination.per_page"
              aria-controls="my-table"
              @change="nextPage($event)"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="quotes.length === 0">
      <b-col>
        <h4 class="display-4">
          No hay Cotizaciones 🧟
        </h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import QuotesTable from '@/components/purchaseOrders/PurchaseOrdersViewTable.vue'

export default {
  components: {
    QuotesTable,
  },

  data() {
    return {
      loading: false,
      paymentStatus: 'by_except_payment_status',
      customerInvoiced: null,
      supplierInvoiced: null,
      statusOptions: [
        {
          text: 'Todas',
          value: 'by_except_payment_status',
        },
        {
          text: this.$t('awaiting_payment_approval'),
          value: 'awaiting_payment_approval',
        },
        {
          text: this.$t('payment_approved'),
          value: 'payment_approved',
        },
        {
          text: this.$t('payment_rejected'),
          value: 'payment_rejected',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('quotes', ['quotes', 'quotesPagination']),
  },

  created() {
    this.loading = true
    this.fetchQuotes({
      by_except_payment_status: 'payment_pending',
      by_is_invoiced: this.customerInvoiced,
      by_supplier_is_invoiced: this.supplierInvoiced,
      meta: { pagination: this.quotesPagination },
    }).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('quotes', {
      clearQuotes: 'CLEAR_QUOTES',
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('quotes', ['fetchQuotes']),
    handleFetchQuotes() {
      this.loading = true
      if (this.paymentStatus === 'by_except_payment_status') {
        this.fetchQuotes({
          by_except_payment_status: 'payment_pending',
          by_is_invoiced: this.customerInvoiced,
          by_supplier_is_invoiced: this.supplierInvoiced,
          meta: { pagination: this.quotesPagination },
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.fetchQuotes({
          by_payment_status: this.paymentStatus,
          by_is_invoiced: this.customerInvoiced,
          by_supplier_is_invoiced: this.supplierInvoiced,
          meta: { pagination: this.quotesPagination },
        }).finally(() => {
          this.loading = false
        })
      }
    },

    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.handleFetchQuotes()
    },
  },
}
</script>

<style></style>
